
import Login from './Login.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import Dashboard from './Dashboard.js'

const code = new URLSearchParams(window.location.search).get('code')

function App() {
  return code ? <Dashboard code = {code} /> : <Login />
  // return <Login />
}

export default App;
